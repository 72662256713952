import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IPrivilegeOption } from '../../types/addedValuePerMonth';

const GET_PRIVILEGE_OPTIONS_WIDGET_URL_PATH =
  '/api/v2/insights-service/hour-kpi-per-month-privilege';
const GET_PRIVILEGE_OPTIONS_WIDGET_MSW_STATUS_KEY = 'getPrivilegeOptionsAPI';

const GET_PRIVILEGE_OPTIONS_WIDGET_KEY = 'GET_PRIVILEGE_OPTIONS_WIDGET_KEY';

const getAddedValuePerMonthPrivilegeReportingWidget = (): Promise<IPrivilegeOption> =>
  getFetch({
    path: GET_PRIVILEGE_OPTIONS_WIDGET_URL_PATH,
    key: GET_PRIVILEGE_OPTIONS_WIDGET_MSW_STATUS_KEY,
  });

export const useGetAddedValuePerMonthPrivilegeOptions = () => {
  const { data, isError, ...restProps } = useQuery([GET_PRIVILEGE_OPTIONS_WIDGET_KEY], () =>
    getAddedValuePerMonthPrivilegeReportingWidget(),
  );

  if (data?.entities) {
    const privilegeOptions = data.entities?.map((entity: any) => entity.properties);
    return {
      privilegeOptions,
      totalCount: data?.properties?.count,
      isError: false,
      isEmpty: data.entities.length <= 0,
      ...restProps,
    };
  }

  return {
    privilegeOptions: [],
    totalCount: 0,
    isError: false,
    isEmpty: true,
    ...restProps,
  };
};
