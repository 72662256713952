export default {
  Properties: {
    WidgetList: [
      {
        Type: 'OverdueInvoices',
      },
      {
        Type: 'AverageHourlyRate',
      },
      {
        Type: 'InvoicedPerMonth',
      },
      {
        Type: 'PlannedAbsence',
      },
      {
        Type: 'ProjectEconomyHealth',
      },
      {
        Type: 'TimeTrackingDelay',
      },
      {
        Type: 'VacationCalculation',
      },
      {
        Type: 'HourKPI',
      },
      {
        Type: 'HourKPIWithBAR',
      },
    ],
    IsReportWidgetActive: false,
  },
  Links: [
    {
      Href: 'http://localhost/timelog/api/v2/widget/all-active-widgets',
      Rel: 'self',
    },
  ],
};
