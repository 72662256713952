import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { FilterValues, ISavedViewResponse, ViewOptionsValues } from '../savedViewAPI';

const GET_SAVED_VIEW_URL_PATH = '/api/v2/filter-view';

export const GET_SAVED_VIEW_MSW_STATUS_KEY = 'getSavedViewAPI';
export const GET_SAVED_VIEW_KEY = 'GET_SAVED_VIEW';

const getSavedView = (pageIdentifier: string): Promise<ISavedViewResponse> =>
  getFetch({
    path: `${GET_SAVED_VIEW_URL_PATH}/${pageIdentifier}`,
    key: GET_SAVED_VIEW_MSW_STATUS_KEY,
  });

export const useGetSavedView = (pageIdentifier: string) => {
  const { data, isError, ...queryRest } = useQuery([GET_SAVED_VIEW_KEY, pageIdentifier], () =>
    getSavedView(pageIdentifier),
  );

  const filteredSaveView = data?.entities
    ? data.entities.map(({ properties }) => {
        const viewOptions: ViewOptionsValues[] = [];
        const filterValues = properties.filterValues
          ?.map((filterValue) => {
            if (filterValue.filterType === 'Textbox') {
              viewOptions.push(filterValue as ViewOptionsValues);
              return null; // Remove from filterValues
            }
            return {
              ...filterValue,
            } as unknown as FilterValues;
          })
          .filter(Boolean);

        return {
          ...properties,
          filterValues,
          viewOptions,
        };
      })
    : [];

  const savedView = filteredSaveView.map((view) => ({
    ...view,
    filterValues: view?.filterValues?.map((filterValue) => ({
      ...filterValue,
      value: filterValue?.value?.map((val: any) => {
        const { key, ...rest } = val;
        return {
          ...rest,
          label: key,
        } as unknown as FilterValues;
      }),
    })),
  }));

  return {
    savedView,
    ...queryRest,
  };
};
