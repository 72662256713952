import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useFilterDispatch, useMatchSelectedFilterKey } from 'src/stores/FilterStore';
import { IChildInputComponent, IOnChangeInput } from '../InputContainerTypes';

interface IOnChangeHandler extends Omit<IChildInputComponent, 'filterId'> {
  setListValue: Dispatch<SetStateAction<IOnChangeInput[] | null>>;
}
export const useOnChangeHandler = ({
  contentUrl,
  fieldLabel,
  childFilters = [],
  parentFilters = [],
  isInclude,
  setListValue,
  type,
}: IOnChangeHandler) => {
  const dispatch = useFilterDispatch();
  const matchChild = useMatchSelectedFilterKey(childFilters);

  return (event: SyntheticEvent, newValue: Array<IOnChangeInput>, reason: string) => {
    if (dispatch) {
      if (reason === 'selectOption' || reason === 'removeOption') {
        dispatch({
          type: 'ADD_OR_UPDATE_FILTER',
          payload: {
            [fieldLabel]: {
              label: fieldLabel,
              values: Array.isArray(newValue) ? newValue : [newValue],
              contentUrl,
              childFilters,
              parentFilters,
              isInclude,
              type,
            },
          },
        });

        matchChild?.forEach((filterID) => {
          dispatch({ type: 'RESET_FILTER_ID', payload: { filterID } });
        });
      } else if (reason === 'clear') {
        dispatch({ type: 'REMOVE_FILTER', payload: { filterID: fieldLabel } });
      }
    }
    setListValue(newValue.map((option) => option));
  };
};

export default useOnChangeHandler;
