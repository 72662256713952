import { Grid, Stack, Tooltip } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IActions } from 'src/apis/projectsAPI/types';
import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/components/mui-components';

interface IProjectActionDialog {
  showActionUpdate: boolean;
  setShowActionUpdate: Dispatch<SetStateAction<boolean>>;
  applyProjectsUpdate: (uri?: string, validDate?: Date, action?: any) => void;
  selectedAction: IActions;
}

export const ProjectActionDialog = ({
  showActionUpdate,
  setShowActionUpdate,
  applyProjectsUpdate,
  selectedAction,
}: IProjectActionDialog) => {
  const { t } = useTranslation('searchProjects');

  const [validDate, setValidDate] = useState<Date | null>(new Date());

  const handleValidDateChange = (date: Date | null) => {
    if (date instanceof Date) {
      const offset = -date.getTimezoneOffset();
      date.setHours(Math.trunc(offset / 60), offset % 60);
    }
    setValidDate(date);
  };

  const setTodayDate = () => {
    handleValidDateChange(new Date());
  };

  const handleActionsApply = () => {
    if (selectedAction) {
      const updateKey = selectedAction.uri === 'status' ? 'status' : 'stage';
      applyProjectsUpdate(selectedAction.uri, validDate ?? undefined, {
        [updateKey]: selectedAction.body?.[updateKey],
        value: selectedAction.name,
      });
    }
  };

  return (
    <Dialog
      open={!!showActionUpdate}
      onClose={() => setShowActionUpdate(false)}
      aria-labelledby="dialog-project-action"
      fullWidth
      maxWidth="xs"
      data-automation-id="ProjectActionDialog"
    >
      <DialogTitle id="dialog-project-action">
        {selectedAction?.uri === 'stage' ? t('ChangeStageToText') : t('ChangeStatusToText')}{' '}
        {selectedAction?.uri === 'stage'
          ? selectedAction.name
          : t(`Status${selectedAction.name}Text`)}{' '}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" sx={{ mt: '0' }}>
          <Grid item xs={7}>
            <Stack direction="row">
              <DatePicker
                label={t('ValidFromText')}
                format="dd-MM-yyyy"
                onChange={(value) => handleValidDateChange(value)}
                value={validDate || null}
                slotProps={{
                  textField: { size: 'small', id: 'valid-date-picker' },
                }}
              />
              <Tooltip title={t('SetTodayDateTooltipText')}>
                <Button
                  data-automation-id="SetTodayDateTooltipButton"
                  onClick={() => setTodayDate()}
                >
                  {t('TodayDateButton')}
                </Button>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowActionUpdate(false)}>
          {t('CancelButton')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleActionsApply();
            setShowActionUpdate(false);
          }}
          data-automation-id="ActionOkButton"
        >
          {t('OkButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
