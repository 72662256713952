import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFetch } from 'src/utils/fetcher';
import { GET_SAVED_VIEW_KEY } from '../get/getSavedViewAPI';

const DELETE_SAVED_VIEW_URL_PATH = '/api/v2/filter-view';
export const DELETE_SAVED_VIEW_MSW_STATUS_KEY = 'deleteSavedViewAPI';

const deleteSavedView = (filterViewId: string) =>
  deleteFetch({
    path: `${DELETE_SAVED_VIEW_URL_PATH}/${filterViewId}`,
    key: DELETE_SAVED_VIEW_MSW_STATUS_KEY,
  });

export const useDeleteSavedView = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSavedView, {
    onSuccess: () => Promise.all([queryClient.invalidateQueries([GET_SAVED_VIEW_KEY])]),
  });
};
