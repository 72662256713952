import { useGetLocale } from 'src/components/global/LocaleProvider';
import { Trans, useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import {
  useGetAddedValuePerMonthPrivilegeOptions,
  useGetAddedValuePerMonthReportingWidget,
} from 'src/apis/reportingWidgetsAPI';
import {
  IAddedValuePerMonthWidgetDataItem,
  IPrivilegeOptionItem,
} from 'src/apis/reportingWidgetsAPI/types/addedValuePerMonth';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  MenuItem,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { barChartPalette, secondaryBarChartPalette } from 'src/constants/colors';
import { Box, CircularProgress, Select } from 'src/components/mui-components';

import { currencyFormatter } from 'src/utils/currency';
import ReportingWidgetCard from '../ReportingWidgetCard';
import { WidgetEmpty, WidgetError } from '../ReportingWidgetState';

interface CustomItemTooltipProps {
  axisValue: string | number | Date | null;
  data: IAddedValuePerMonthWidgetDataItem[];
  alignment: string;
  currencyAbb: string;
  t: (key: string) => string;
  valueFormatter: (value: number) => string;
}

const CustomItemTooltip = ({
  axisValue,
  data,
  alignment,
  currencyAbb,
  t,
  valueFormatter,
}: CustomItemTooltipProps) => {
  const dataPoint = data.find((item) => item.month === axisValue);

  const renderAmountSection = (title: string, amount: number, currency: string) => (
    <Typography gutterBottom>
      {title}: {valueFormatter(amount)} {currency ?? ''}
    </Typography>
  );

  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent>
        <Typography component="h3" gutterBottom>
          <strong>{String(axisValue)}</strong>
        </Typography>
        {alignment === 'reg'
          ? renderAmountSection(
              t('AddedValuePerMonthRegAmount'),
              dataPoint?.amountRegistered ?? 0,
              currencyAbb,
            )
          : renderAmountSection(
              t('AddedValuePerMonthEstAmount'),
              dataPoint?.amountEstimated ?? 0,
              currencyAbb,
            )}
        <Typography gutterBottom>
          {t('AddedValuePerMonthHours')}:{' '}
          {valueFormatter(
            alignment === 'reg' ? dataPoint?.hours ?? 0 : dataPoint?.hoursEstimated ?? 0,
          )}
        </Typography>
        <Typography>
          {t('AverageHourlyRate')} ({alignment === 'reg' ? t('RegText') : t('EstText')}):{' '}
          {alignment === 'reg'
            ? valueFormatter(
                dataPoint?.hours && dataPoint.hours !== 0
                  ? (dataPoint.amountRegistered ?? 0) / dataPoint.hours
                  : dataPoint?.amountRegistered ?? 0,
              )
            : valueFormatter(
                dataPoint?.hoursEstimated && dataPoint.hoursEstimated !== 0
                  ? (dataPoint.amountEstimated ?? 0) / dataPoint.hoursEstimated
                  : dataPoint?.amountEstimated ?? 0,
              )}{' '}
          {currencyAbb ?? ''}
        </Typography>
        <br />
        <Typography component="h3" gutterBottom>
          <strong>{t('YearToDateText')}</strong>
        </Typography>
        {alignment === 'reg'
          ? renderAmountSection(
              t('AddedValuePerMonthRegAmount'),
              dataPoint?.registeredAmountYTD ?? 0,
              currencyAbb,
            )
          : renderAmountSection(
              t('AddedValuePerMonthEstAmount'),
              dataPoint?.estimatedAmountYTD ?? 0,
              currencyAbb,
            )}
        <Typography gutterBottom>
          {t('AddedValuePerMonthHours')}:{' '}
          {valueFormatter(
            alignment === 'reg' ? dataPoint?.hoursYTD ?? 0 : dataPoint?.hoursEstimatedYTD ?? 0,
          )}
        </Typography>
        <Typography>
          {t('AverageHourlyRate')} ({alignment === 'reg' ? t('RegText') : t('EstText')}):{' '}
          {alignment === 'reg'
            ? valueFormatter(
                dataPoint?.hoursYTD && dataPoint.hoursYTD !== 0
                  ? (dataPoint.registeredAmountYTD ?? 0) / dataPoint.hoursYTD
                  : dataPoint?.registeredAmountYTD ?? 0,
              )
            : valueFormatter(
                dataPoint?.hoursEstimatedYTD && dataPoint.hoursEstimatedYTD !== 0
                  ? (dataPoint.estimatedAmountYTD ?? 0) / dataPoint.hoursEstimatedYTD
                  : dataPoint?.estimatedAmountYTD ?? 0,
              )}{' '}
          {currencyAbb ?? ''}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const AddedValuePerMonthReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const { privilegeOptions } = useGetAddedValuePerMonthPrivilegeOptions();

  const [alignment, setAlignment] = useState('reg');
  const [valueFilter, setValueFilter] = useState('1');

  const { widgetName, widgetData, currencyAbb, isError, isLoading, isEmpty } =
    useGetAddedValuePerMonthReportingWidget(valueFilter);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setValueFilter(event.target.value as string);
  };

  const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const parseRowData = (data: IAddedValuePerMonthWidgetDataItem[]) =>
    data.map((item) => ({
      ...item,
      month: translationAnyText(t, `Month${item.month}`),
    }));

  const parsedWidgetData = parseRowData(widgetData);

  const chartSetting = {
    height: 300,
    margin: { left: 100, top: 10 },
    colors: alignment === 'reg' ? barChartPalette : secondaryBarChartPalette,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translateX(-60px)',
      },
    },
  };

  const valueFormatter = (value: number | null) => currencyFormatter(locale, 2).format(value ?? 0);

  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (isError) {
      return <WidgetError />;
    }
    if (isEmpty) {
      return <WidgetEmpty noDataMessage={t('EmptyStateGeneralAlertMessage')} />;
    }
    return (
      <div data-automation-id={`ReportingWidgetBarChart-${widgetName}`}>
        <BarChart
          grid={{ vertical: true }}
          dataset={parsedWidgetData}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'month',
              tickPlacement: 'middle',
              barGapRatio: 0,
              categoryGapRatio: 0.5,
              // To fix param barGapRatio not resolve in typescript issue
              // https://github.com/mui/mui-x/issues/10334
            } as any,
          ]}
          yAxis={[
            {
              label: currencyAbb?.toString() ?? '',
              labelStyle: {
                fontSize: 12,
              },
              valueFormatter,
            },
          ]}
          series={[
            {
              dataKey: alignment === 'reg' ? 'amountRegistered' : 'amountEstimated',
              label:
                alignment === 'reg'
                  ? `${t('AddedValuePerMonthRegAmount')} ${currencyAbb?.toString() ?? ''}`
                  : `${t('AddedValuePerMonthEstAmount')} ${currencyAbb?.toString() ?? ''}`,
              valueFormatter,
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 0,
              labelStyle: {
                fontSize: 12,
              },
              itemMarkHeight: 10,
            },
          }}
          slots={{
            // eslint-disable-next-line react/no-unstable-nested-components
            axisContent: (props) => (
              <CustomItemTooltip
                {...props}
                data={parsedWidgetData}
                alignment={alignment}
                currencyAbb={currencyAbb}
                t={t}
                valueFormatter={valueFormatter}
              />
            ),
          }}
          {...chartSetting}
        />
      </div>
    );
  };

  const convertPrivilegeOptionsToFilterOptions = (options: IPrivilegeOptionItem[]) =>
    options.map((option) => ({
      value: option.filterId,
      label: translationAnyText(t, `AddedValuePerMonth${option.name}`),
    }));

  const filterOptions = convertPrivilegeOptionsToFilterOptions(privilegeOptions);

  return (
    <ReportingWidgetCard
      name={widgetName}
      title={t('AddedValuePerMonth')}
      infoIconText={
        <Trans
          i18nKey="AddedValuePerMonthWidgetDescription"
          key="AddedValuePerMonthWidgetDescription"
          defaults={t('AddedValuePerMonthWidgetDescription')}
          components={{ 1: <br /> }}
        />
      }
      toggleContent={
        <Stack direction="row" sx={{ mr: 3 }}>
          <Select
            labelId="filter-label"
            id="filter-select"
            value={valueFilter}
            label={t('Filter')}
            onChange={handleSelectChange}
            size="small"
            fullWidth
            data-automation-id="AddedValuePerMonthFilterSelect"
            sx={{ minWidth: 180 }}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <ToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignmentChange}
            aria-label={t('AddedValuePerMonth')}
            sx={{ ml: 1 }}
          >
            <ToggleButton
              value="reg"
              title={t('AddedValuePerMonthShowRegisteredValue')}
              data-automation-id="AddedValuePerMonthRegButton"
            >
              {t('RegText')}
            </ToggleButton>
            <ToggleButton
              value="est"
              title={t('AddedValuePerMonthShowEstimatedValue')}
              data-automation-id="AddedValuePerMonthEstButton"
            >
              {t('EstText')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      }
      hasAction
    >
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};
