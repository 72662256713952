import { InfoOutlined } from '@mui/icons-material';
import { Fragment, ReactNode, useState } from 'react';
import { IconButton } from 'src/components/mui-components';
import ReportingWidgetInfoModal from 'src/screens/Home/components/ReportingWidgetInfoModal';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface IReportingWidgetCard {
  title: string;
  name: string;
  infoIconText: string | ReactNode;
  children?: ReactNode;
  toggleContent?: ReactNode;
  hasAction?: boolean;
}

export const ReportingWidgetCard = ({
  name,
  title,
  infoIconText,
  children,
  toggleContent,
  hasAction = false,
}: IReportingWidgetCard) => {
  const { t } = useTranslation('reportingWidgets');
  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card className={`Card-${title}`} variant="outlined" sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                {title}
              </Grid>
              <Grid item sm={12} md={6}>
                <Box display="flex" justifyContent={isMediumScreen ? 'flex-start' : 'flex-end'}>
                  {toggleContent && toggleContent}
                </Box>
              </Grid>
            </Grid>
          </Stack>
        }
        action={
          hasAction && (
            <>
              <Fragment key={`InfoIconButton${name}`}>
                <IconButton
                  title={t('InfoIconTooltipTitle')}
                  size="small"
                  aria-label={`info-icon-button-${name}`}
                  data-automation-id={`InfoIconButton${name}`}
                  sx={{ paddingTop: toggleContent ? '10px' : 'initial' }}
                  onClick={() => setIsOpen(true)}
                >
                  <InfoOutlined />
                </IconButton>
                <ReportingWidgetInfoModal
                  id={name}
                  title={title}
                  infoMessage={infoIconText}
                  openState={isOpen}
                  setOpenState={setIsOpen}
                />
              </Fragment>
              {/*
              // DRO: Will leave the action here for a while since we are going to have this in the next iteration.
              <MuiIconButton size="small" aria-label="action">
              <MoreVert />
            </MuiIconButton> */}
            </>
          )
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default ReportingWidgetCard;
