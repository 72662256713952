import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from 'src/components/mui-components';
import { ISavedViewForm } from 'src/apis/savedViewAPI';
import { Box, Stack, Checkbox, FormControlLabel } from '@mui/material';

interface IViewFormDialog {
  onSubmit: (actionType: string, data: ISavedViewForm) => void;
  form: UseFormReturn<ISavedViewForm, any>;
  showEditDialog: boolean;
  onClose: () => void;
}

export const EditViewDialog = ({ onSubmit, form, showEditDialog, onClose }: IViewFormDialog) => {
  const { t } = useTranslation('savedView');
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  const resetForm = () => {
    form.setValue('title', '');
    form.setValue('isDefault', false);
  };

  return (
    <Dialog
      aria-labelledby="dialog-edit-view"
      fullWidth
      maxWidth="xs"
      onClose={() => onClose()}
      open={!!showEditDialog}
    >
      <DialogTitle id="dialog-edit-view">{t('UpdateViewModalTitle')}</DialogTitle>
      <DialogContent>
        <Stack sx={{ mt: 2 }}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                data-automation-id="EditViewTitle"
                label={t('ViewNameText')}
                variant="outlined"
                ariaLabel={t('ViewNameText')}
                required
                error={!!errors.title?.message?.toString()}
                helperText={errors.title?.message?.toString()}
                {...field}
              />
            )}
          />
          <FormControlLabel
            control={
              <Controller
                name="isDefault"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={t('DialogIsDefaultText')}
          />
          <Box alignSelf="flex-start">
            <Button
              variant="text"
              onClick={handleSubmit((data) => onSubmit('delete', data))}
              sx={{ pl: 0 }}
            >
              {t('DeleteViewText')}
            </Button>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            resetForm();
            onClose();
          }}
        >
          {t('DialogCancelText')}
        </Button>
        <Button
          variant="contained"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit((data) => {
            resetForm();
            onSubmit('edit', data);
          })}
          autoFocus
        >
          {t('DialogSaveText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
