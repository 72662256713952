import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'src/components/mui-components';

interface IRemoveRPModalProps {
  showRemoveResourcePlannerDialog: boolean;
  setShowRemoveResourcePlannerDialog: Dispatch<SetStateAction<boolean>>;
  applyProjectsUpdate: (uri?: string, validDate?: Date, action?: any) => void;
}

export const ProjectRemoveRPDialog: FC<IRemoveRPModalProps> = ({
  showRemoveResourcePlannerDialog,
  setShowRemoveResourcePlannerDialog,
  applyProjectsUpdate,
}) => {
  const { t } = useTranslation('searchProjects');

  return (
    <Dialog
      open={showRemoveResourcePlannerDialog}
      onClose={() => setShowRemoveResourcePlannerDialog(false)}
      aria-labelledby="dialog-remove-resource-planner"
      aria-describedby="dialog-remove-resource-planner-desc"
    >
      <DialogTitle id="dialog-remove-resource-planner">
        {t('RemoveProjectsFromResourcePlannerTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-remove-resource-planner-desc">
          {t('RemoveProjectsFromResourcePlannerConfirmMessage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          data-automation-id="RemoveResourcePlannerModalCancelButton"
          onClick={() => setShowRemoveResourcePlannerDialog(false)}
        >
          {t('CancelButton')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            applyProjectsUpdate('resource-planner', undefined, {
              isAddingToResourcePlanner: false,
            });
            setShowRemoveResourcePlannerDialog(false);
          }}
          autoFocus
          data-automation-id="RemoveResourcePlannerModalSaveButton"
        >
          {t('SaveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
