import { ISavedView } from 'src/apis/savedViewAPI';
import {
  Description,
  AssignmentTurnedIn,
  PlayArrow,
  Pause,
  Done,
  Archive,
  Close,
} from '@mui/icons-material';

export const SNACKBAR_CONFIG = [
  {
    type: 'save',
    title: 'SaveViewSuccessToastTitle',
    description: 'SaveViewSuccessToastBody',
  },
  {
    type: 'edit',
    title: 'UpdateViewSuccessToastTitle',
    description: 'UpdateViewSuccessToastBody',
  },
  {
    type: 'delete',
    title: 'DeleteViewSuccessToastTitle',
    description: 'DeleteViewSuccessToastBody',
  },
  {
    type: 'changes',
    title: 'ChangesViewSuccessToastTitle',
    description: 'ChangesViewSuccessToastBody',
  },
  {
    type: 'duplicate',
    title: 'DuplicateViewSuccessToastTitle',
    description: 'DuplicateViewSuccessToastBody',
  },
];

export const INITIAL_SAVED_VIEW: ISavedView = {
  title: '',
  isDefault: false,
  createdDate: new Date(),
  updatedDate: new Date(),
  filters: {},
  viewOptions: {
    searchText: '',
  },
};

export const SEARCH_TEXT_FILTER = {
  name: 'SearchText',
  value: '',
  isInclusive: true,
  filterType: 'Textbox',
};

export const statusValueOptions = [
  { label: 'StatusQuoteText', value: 0, icon: Description },
  { label: 'StatusApprovedText', value: 1, icon: AssignmentTurnedIn },
  { label: 'StatusInProgressText', value: 2, icon: PlayArrow },
  { label: 'StatusOnHoldText', value: 3, icon: Pause },
  { label: 'StatusCompletedText', value: 4, icon: Done },
  { label: 'StatusArchivedText', value: 5, icon: Archive },
  { label: 'StatusCancelledText', value: 6, icon: Close },
];

export const preDefinedFilters = ['MyProjects', 'Active', 'All'];
