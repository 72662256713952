import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from 'src/components/mui-components';
import { INewSavedView, ISavedViewForm } from 'src/apis/savedViewAPI';
import { Checkbox, Box, Tab, Tabs, Typography, FormControlLabel, Stack } from '@mui/material';
import { useState } from 'react';
import { translationAnyText } from 'src/utils/translation';
import { preDefinedFilters } from 'src/screens/SearchProjects/constants';

interface IViewFormDialog {
  onSubmit: (actionType: string, data: ISavedViewForm) => void;
  form: UseFormReturn<ISavedViewForm, any>;
  showSaveDialog: boolean;
  onClose: () => void;
  selectedSaveViewData: INewSavedView | undefined;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const FilterTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`filter-tabpanel-${index}`}
      aria-labelledby={`filter-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const SaveViewDialog = ({
  onSubmit,
  form,
  showSaveDialog,
  onClose,
  selectedSaveViewData,
}: IViewFormDialog) => {
  const { t } = useTranslation('savedView');
  const [selectedTab, setSelectedTab] = useState('save');

  const translatePreDefinedFilter = (filter: string) => {
    if (preDefinedFilters.includes(filter)) {
      return translationAnyText(t, `SavedView${filter}TabText`);
    }
    return filter;
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  const handleChange = (event: React.SyntheticEvent, tab: string) => {
    setSelectedTab(tab);
    if (tab === 'save') {
      form.setValue('title', '');
      form.setValue('isDefault', false);
    } else if (selectedSaveViewData) {
      form.setValue('title', translatePreDefinedFilter(selectedSaveViewData.name));
      form.setValue('isDefault', selectedSaveViewData.isDefault);
    }
  };

  const resetTab = () => {
    form.setValue('title', '');
    form.setValue('isDefault', false);
    setSelectedTab('save');
  };

  return (
    <Dialog
      aria-labelledby="dialog-save-view"
      fullWidth
      maxWidth="xs"
      onClose={() => onClose()}
      open={!!showSaveDialog}
    >
      <DialogTitle id="dialog-save-view">{t('SaveViewModalTitle')}</DialogTitle>
      <DialogContent>
        <Tabs value={selectedTab} onChange={handleChange} aria-label="Saved view">
          <Tab value="save" label={t('SaveNewTabText')} wrapped data-automation-id="SaveNewTab" />
          <Tab
            value="changes"
            label={t('ReplaceExistingTabText')}
            wrapped
            disabled={!selectedSaveViewData}
            data-automation-id="ReplaceTab"
          />
        </Tabs>
        <Box sx={{ mt: 3 }}>
          <FilterTabPanel value={selectedTab} index="save">
            <Stack>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    data-automation-id="SavedViewTitle"
                    label={t('ViewNameText')}
                    variant="outlined"
                    ariaLabel={t('ViewNameText')}
                    required
                    error={!!errors?.title?.message?.toString()}
                    helperText={errors?.title?.message?.toString()}
                    {...field}
                  />
                )}
              />
              <FormControlLabel
                control={
                  <Controller
                    name="isDefault"
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={t('DialogIsDefaultText')}
              />
            </Stack>
          </FilterTabPanel>
          <FilterTabPanel value={selectedTab} index="changes">
            <Stack gap={0}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography variant="small" color="textSecondary">
                      {t('ReplacingText')}
                    </Typography>
                    <Typography variant="body2" data-automation-id="ReplacingText">
                      {field.value}
                    </Typography>
                  </>
                )}
              />
            </Stack>
          </FilterTabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            resetTab();
            onClose();
          }}
        >
          {t('DialogCancelText')}
        </Button>
        <Button
          variant="contained"
          disabled={selectedTab === 'save' ? !isDirty || !isValid : false}
          onClick={handleSubmit((data: ISavedViewForm) => {
            resetTab();
            onSubmit(selectedTab, data);
          })}
          autoFocus
        >
          {t('DialogSaveText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
