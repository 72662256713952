export default {
  Class: 'hourkpiprivilege',
  Properties: {
    Count: 4,
  },
  Entities: [
    {
      Class: 'hourkpiprivilege',
      Properties: {
        FilterId: 1,
        Name: 'HasMyHours',
      },
    },
    {
      Class: 'hourkpiprivilege',
      Properties: {
        FilterId: 2,
        Name: 'HasMyProjects',
      },
    },
    {
      Class: 'hourkpiprivilege',
      Properties: {
        FilterId: 3,
        Name: 'HasDepartment',
      },
    },
    {
      Class: 'hourkpiprivilege',
      Properties: {
        FilterId: 4,
        Name: 'HasAllProjects',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/hour-kpi-per-month-privilege?siteid=520abe0d-c7e2-4a73-a5f2-c349c48e4e25',
      Rel: 'self',
    },
  ],
};
