import { Grid } from 'src/components/mui-components';
import classNames from 'classnames';
import AverageHourlyRateReportingWidget from '../AverageHourlyRateReportingWidget';
import InvoicedPerMonthReportingWidget from '../InvoicedPerMonthReportingWidget';
import OverdueInvoicesReportingWidget from '../OverdueInvoicesReportingWidget';
import PlannedAbsenceReportingWidget from '../PlannedAbsenceReportingWidget';
import ProjectEconomyHealthReportingWidget from '../ProjectEconomyHealthReportingWidget';
import ReportingWidgetControl from './components/ReportingWidgetControl';
import { TimeTrackingDelayReportingWidget } from '../TimeTrackingDelayReportingWidget';
import { VacationCalculationReportingWidget } from '../VacationCalculationReportingWidget';
import {
  AddedValuePerMonthReportingWidget,
  AddedValueBarPerMonthReportingWidget,
} from '../AddedValuePerMonthReportingWidget';
import styles from './ReportingWidgetContainer.module.scss';

export const ReportingWidgetContainer = () => (
  <div data-automation-id="ReportWidgetContainer" className={classNames(styles.widgetContainer)}>
    <Grid container>
      <ReportingWidgetControl widgetTypeName="HourKPI">
        <Grid item xs={12} md={12} lg={12}>
          <AddedValuePerMonthReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="HourKPIWithBAR">
        <Grid item xs={12} md={12} lg={12}>
          <AddedValueBarPerMonthReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="InvoicedPerMonth">
        <Grid item xs={12} md={12} lg={12}>
          <InvoicedPerMonthReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="AverageHourlyRate">
        <Grid item xs={12} md={12} lg={12}>
          <AverageHourlyRateReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="PlannedAbsence">
        <Grid item xs={12} md={12} lg={12}>
          <PlannedAbsenceReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="ProjectEconomyHealth">
        <Grid item xs={12} md={12} lg={12}>
          <ProjectEconomyHealthReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="OverdueInvoices">
        <Grid item xs={12} md={12} lg={12}>
          <OverdueInvoicesReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="TimeTrackingDelay">
        <Grid item xs={12} md={6} lg={6}>
          <TimeTrackingDelayReportingWidget />
        </Grid>
      </ReportingWidgetControl>

      <ReportingWidgetControl widgetTypeName="VacationCalculation">
        <Grid item xs={12} md={12} lg={12}>
          <VacationCalculationReportingWidget />
        </Grid>
      </ReportingWidgetControl>
    </Grid>
  </div>
);

export default ReportingWidgetContainer;
