export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: {
      HourKPIPerMonths: [
        {
          Year: 2024,
          Month: 'Jan',
          AmountRegistered: 61500.59,
          AmountEstimated: 51500.5,
          Hours: 20,
          HoursEstimated: 33,
          RegisteredAmountYTD: 125444,
          EstimatedAmountYTD: 3216,
          HoursYTD: 20,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Feb',
          AmountRegistered: 66200.5,
          AmountEstimated: 46200.5,
          Hours: 23,
          HoursEstimated: 33,
          RegisteredAmountYTD: 125444,
          EstimatedAmountYTD: 3216,
          HoursYTD: 20,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Mar',
          AmountRegistered: 69500.5,
          AmountEstimated: 48500.5,
          Hours: 14,
          HoursEstimated: 33,
          RegisteredAmountYTD: 125444,
          EstimatedAmountYTD: 3216,
          HoursYTD: 20,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Apr',
          AmountRegistered: 64000.5,
          AmountEstimated: 44000.5,
          Hours: 30,
          HoursEstimated: 33,
          RegisteredAmountYTD: 125444,
          EstimatedAmountYTD: 3216,
          HoursYTD: 20,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'May',
          AmountRegistered: 68000.5,
          AmountEstimated: 48000.5,
          Hours: 20,
          HoursEstimated: 33,
          RegisteredAmountYTD: 125444,
          EstimatedAmountYTD: 3216,
          HoursYTD: 20,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Jun',
          AmountRegistered: 44123.5,
          AmountEstimated: 34123.5,
          Hours: 10,
          HoursEstimated: 33,
          RegisteredAmountYTD: 125444,
          EstimatedAmountYTD: 3216,
          HoursYTD: 0,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Jul',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Hours: 0,
          HoursEstimated: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Aug',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Hours: 0,
          HoursEstimated: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Sep',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Hours: 0,
          HoursEstimated: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Oct',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Hours: 0,
          HoursEstimated: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Nov',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Hours: 0,
          HoursEstimated: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Dec',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Hours: 0,
          HoursEstimated: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
      ],
      TotalHoursCurrentYear: 0.0,
      TotalAmountRegisteredCurrentYear: 0.0,
      TotalAmountEstimatedCurrentYear: 0.0,
    },
    Type: 'HourKPI',
    SystemCurrencyAbb: 'DKK',
    Width: 0,
    Height: 0,
    SortOrder: 0,
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/hour-kpi-per-month?siteid=520abe0d-c7e2-4a73-a5f2-c349c48e4e25',
      Rel: 'self',
    },
  ],
};
