export const barChartPalette = [
  '#3646B5',
  '#247984',
  '#4A6728',
  '#005B00',
  '#D56200',
  '#BA1A1A',
  '#821E62',
  '#212121',
];

export const secondaryBarChartPalette = [
  '#3E8BFC',
  '#247984',
  '#4A6728',
  '#005B00',
  '#D56200',
  '#BA1A1A',
  '#821E62',
  '#212121',
];
