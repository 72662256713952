import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from 'src/components/mui-components/Stack';
import {
  useFilterDispatch,
  useFullFilterList,
  useSelectedFilterListInArray,
} from 'src/stores/FilterStore';
import { translationAnyText } from 'src/utils/translation';
import { Box, Button } from 'src/components/mui-components';
import { Header } from './components/Header';
import { InputGroup } from './components/InputGroup';
import { useFocusTrapping } from './helpers/focusTrapping';
import styles from './SideBar.module.scss';

import { FilterItem } from '../MainContainer/components/FilterItem';

interface IFilterList {
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

export const FilterList = ({ activateSidePanelButtonRef }: IFilterList) => {
  const dispatch = useFilterDispatch();
  const filterList = useFullFilterList();
  const selectedFilterList = useSelectedFilterListInArray();

  const { t } = useTranslation('filter');
  const sideBarRef = useRef<HTMLDivElement>(null);

  const showResetButton = selectedFilterList.length;

  useFocusTrapping(sideBarRef);

  return (
    <div ref={sideBarRef}>
      <Stack gap={3} data-automation-id="SidePanel">
        <Header activateSidePanelButtonRef={activateSidePanelButtonRef} />
        <Stack gap={1} className={styles.filterList}>
          {showResetButton ? (
            <Box ml="auto">
              <Button
                variant="text"
                size="small"
                onClick={() => dispatch?.({ type: 'RESET_FILTER' })}
                data-automation-id="FilterContainerRemoveFilters"
              >
                {t('ClearAllFiltersButton')}
              </Button>
            </Box>
          ) : null}
          {selectedFilterList.map(({ id, ...restProps }) => (
            <FilterItem {...restProps} id={id} key={id} />
          ))}
        </Stack>
        {filterList.map(({ filterCategory = '', filterItems }) => (
          <InputGroup
            key={filterCategory}
            filterCategory={translationAnyText(t, `FilterCategory${filterCategory}`)}
            filterItems={filterItems || []}
          />
        ))}
      </Stack>
    </div>
  );
};
